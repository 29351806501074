<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
    </ol>
    <h1 class="page-header">
      รายงานผลการเลือกตั้ง
      <small>
        รายงานผลการเลือกตั้งอย่างไม่เป็นทางการ
        <font class="text-red-darker">(Refresh ข้อมูลในอีก {{ timerCount }} วินาที)</font>
      </small>
    </h1>
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-teal">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-globe fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">ผู้มีสิทธิเลือกตั้ง</div>
            <div class="stats-number">{{ parseInt(summary.elector).toLocaleString() }}</div>
            <div class="stats-progress progress">
              <div class="progress-bar" style="width: 100%"></div>
            </div>
            <div class="stats-desc"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-dollar-sign fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">ผู้มาใช้สิทธิเลือกตั้ง</div>
            <div class="stats-number">{{ summary.comein ? parseInt(summary.comein).toLocaleString() : 0 }}</div>
            <div class="stats-progress progress">
              <div class="progress-bar" :style="'width: ' + ((summary.comein ? summary.comein : 0) * 100) / 1070677 + '%'"></div>
            </div>
            <div class="stats-desc"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-indigo">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-archive fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">หน่วยเลือกตั้งทั้งหมด</div>
            <div class="stats-number">{{ parseInt(summary.units).toLocaleString() }}</div>
            <div class="stats-progress progress">
              <div class="progress-bar" style="width: 0%"></div>
            </div>
            <div class="stats-desc"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-dark">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-comment-alt fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">หน่วยเลือกตั้งที่ส่งผลแล้ว</div>
            <div class="stats-number">{{ summary.send ? parseInt(summary.send).toLocaleString() : 0 }}</div>
            <div class="stats-progress progress">
              <div class="progress-bar" :style="'width: ' + ((summary.send ? summary.send : 0) * 100) / 2326 + '%'"></div>
            </div>
            <div class="stats-desc"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <panel noButton="true">
          <template slot="header">
            <h4 class="panel-title">ผลคะแนนเลือกตั้ง นายก อบจ.สุรินทร์</h4>
          </template>
          <div class="text-center">
            <h4>ผลคะแนนเลือกตั้ง นายก อบจ.สุรินทร์</h4>
            <h5 class="text-red-darker">อย่างไม่เป็นทางการ</h5>
          </div>
          <div class="table-responsive">
            <table class="table table-striped m-b-0">
              <thead>
                <tr>
                  <th class="text-center" width="100px">หมายเลขผู้สมัคร</th>
                  <th class="text-center" width="1%"></th>
                  <th class="text-center">ชื่อตัว ชื่อสกุล</th>
                  <th class="text-center" width="1%">คะแนน</th>
                  <th class="text-center" width="1%">ลำดับที่</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="applicant in app_provs" :key="applicant.id">
                  <td width="100px" class="text-center">
                    <h1>{{ applicant.number }}</h1>
                  </td>
                  <td width="1%" class="with-img">
                    <img :src="applicant.img_path" class="rounded h-50px" />
                  </td>
                  <td>
                    <h5 class="username text-ellipsis m-t-10">{{ applicant.full_name }}</h5>
                  </td>
                  <td width="1%" class="text-right">
                    <h5 class="m-t-10">{{ parseInt(applicant.vote).toLocaleString() }}</h5>
                  </td>
                  <td width="1%" class="text-center">
                    <h1 class="m-t-5 text-red-darker">{{ parseInt(applicant.no).toLocaleString() }}</h1>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard-v2',
  data() {
    return {
      auto_area: true,
      refreshSec: 15,
      timerCount: 15,
      summary: {},
      app_provs: [],
      app_areas: [],
      area_no: 1,
      area_id: 1,
      district_id: 3201,
      districts: {
        3201: 'อ.เมืองสุรินทร์',
        3202: 'อ.ชุมพลบุรี',
        3203: 'อ.ท่าตูม',
        3204: 'อ.จอมพระ',
        3205: 'อ.ปราสาท',
        3206: 'อ.กาบเชิง',
        3207: 'อ.รัตนบุรี',
        3208: 'อ.สนม',
        3209: 'อ.ศีขรภูมิ',
        3210: 'อ.สังขะ',
        3211: 'อ.ลำดวน',
        3212: 'อ.สำโรงทาบ',
        3213: 'อ.บัวเชด',
        3214: 'อ.พนมดงรัก',
        3215: 'อ.ศรีณรงค์',
        3216: 'อ.เขวาสินรินทร์',
        3217: 'อ.โนนนารายณ์',
      },
    }
  },
  mounted() {
    this.getApplicantProv()
    this.getApplicantArea(this.area_id)
    this.getSummary()
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
            if (this.timerCount == 0) {
              if (this.auto_area) {
                this.getNextArea()
              } else {
                this.getApplicantArea(this.area_id)
              }
              this.getApplicantProv()
              this.getSummary()
              this.timerCount = this.refreshSec
            }
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    getSummary() {
      let url = `election_units?t=get_summary`
      this.axios.get(url).then((res) => {
        this.summary = res.data
      })
    },
    getPreArea() {
      if (this.area_id == 1) {
        this.getApplicantArea(36)
      } else {
        this.getApplicantArea(this.area_id - 1)
      }
    },
    getNextArea() {
      if (this.area_id == 36) {
        this.getApplicantArea(1)
      } else {
        this.getApplicantArea(this.area_id + 1)
      }
    },
    getApplicantProv() {
      let url = `applicant_provinces?t=get_score`
      this.axios.get(url).then((res) => {
        this.app_provs = res.data.data
      })
    },
    getApplicantArea(election_area_id) {
      let url = `applicant_areas?t=get_score&election_area_id=${election_area_id}`
      this.axios.get(url).then((res) => {
        this.app_areas = res.data.data
        this.district_id = res.data.district_id
        this.area_id = res.data.area_id
        this.area_no = res.data.area_no
      })
    },
  },
}
</script>
